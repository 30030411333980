import { Log } from './types';
import { Controls, FiltersName } from './hooks/useControls';

type Matcher = (log: Log, controls: Controls) => boolean;

const FILTER_MATCHERS: Record<FiltersName, Matcher> = {
  levels: (log: Log, controls: Controls) => !!log.level && controls.levels.includes(log.level),
  senders: (log: Log, controls: Controls) => !!log.sender && controls.senders.includes(log.sender),
  message: (log: Log, controls: Controls) => {
    const search = controls.message.toLowerCase();
    return log.message.toLowerCase().includes(search);
  },
};

const logsSorter = (log1: Log, log2: Log) => {
  const time1 = log1?.time?.getTime() ?? 0;
  const time2 = log2?.time?.getTime() ?? 0;
  return time1 - time2;
};


const getFilterMatchers = (controls: Controls) => Object.entries(FILTER_MATCHERS)
  .reduce((acc, current) => {
    const [name, matcher] = current;

    const filters = controls[name as FiltersName];
    if (filters.length) {
      acc.push(matcher);
    }

    return acc;
  }, [] as Matcher[]);

export const getDisplayLogs = (logs: Log[], controls: Controls) => {
  if(!controls.senders.length || !controls.levels.length) {
      return [];
  }

  const matchers = getFilterMatchers(controls);

  const filteredLogs = logs.filter(
    (log) => !matchers.find((isMatch) => !isMatch(log, controls)),
  );

  return filteredLogs.sort(logsSorter);
};
