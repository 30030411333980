type Props = {
	value: string;
	label?: string;
	onChange: (value: string) => void;
};

export const Input: React.FC<Props> = ({ value, label, onChange }) => {
	const id = `inputid-${label}`;

	return (
		<div className='flex flex-col'>
			{!!label && (
				<label htmlFor={id} className='mb-[5px]'>
					{label}
				</label>
			)}
			<input
				id={id}
				type='text'
				value={value}
				onChange={(e) => onChange(e.target.value)}
			/>
		</div>
	);
};
