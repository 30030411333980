import { useState } from 'react';
import { Level, Log } from '../types';

type Summary = {
    logs: Log[];
    senders: string[];
    levels: Level[];
};

const INIT_SUMMARY = {
    logs: [],
    senders: [],
    levels: [],
};

const getUniqueState = (state: any[], input: any) =>
    input && !state.includes(input) ? [...state, input] : state;

export const useLogsState = () => {
    const [summary, setSummary] = useState<Summary>(INIT_SUMMARY);

    const resetAll = () => setSummary(INIT_SUMMARY);
    const resetLogs = () => setSummary(current => ({...current, logs: []}));

    const add = (log: Log) => setSummary((state) => ({
        logs: [...state.logs, log],
        senders: getUniqueState(state.senders, log.sender),
        levels: getUniqueState(state.levels, log.level),
    }));

    const { logs, senders, levels } = summary;
    return {
        values: logs,
        levels,
        senders,
        add,
        resetAll,
        resetLogs,
    };
};
