import { Option } from '../types';
import { Checkbox } from './checkbox';

type Props<Value> = {
	title?: string;
	options: Option<Value>[];
	selected: Value[];
	onChange: (input: Value) => void;
};

export const CheckboxGroup = <Value,>(props: Props<Value>) => {
	const { title, options, selected, onChange } = props;
	return (
		<div>
			{!!title && <p className='font-bold'>{title}</p>}
			{options.map((option) => (
				<Checkbox
					key={`${option.value}`}
					label={option.label}
					checked={selected.includes(option.value)}
					onChange={() => onChange(option.value)}
				/>
			))}
		</div>
	);
};
