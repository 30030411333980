import {Log} from "../types";
import uniqueId from "lodash/uniqueId";

const formatDefault = (message: string) => ({ level: 'INFO', message });

const parseArray = (input: any[]) => {
    if (input.length < 4) {
        return formatDefault(input.join(';'));
    }

    const [timeISO, level, sender, ...messages] = input;
    const message = messages
        .map(current => current instanceof Object ? JSON.stringify(current) : current)
        .join(';');
    return { timeISO, level, sender, message };
}

const parseString = (input: string) => {
    const matches = input.match(
        /^(?<timeISO>\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)?[,\s]*(?<level>[A-Z]+)?[,\s]*(\[(?<sender>[A-Z]+)\])?[,\s]*(?<message>.*)$/i,
    );
    return matches?.groups ?? formatDefault(input);
}

const extractData = (input: any) => {
    if (Array.isArray(input)) {
        return parseArray(input);
    }

    if (typeof input === 'string') {
        return parseString(input);
    }

    return formatDefault(`${input}`);
};

export const parseLog = (input: any): Log => {
    console.log(input); //duplicating everything to network on purpose
    const parsedData = extractData(input);

    const { timeISO, sender, message, ...rest } = parsedData;
    const id = uniqueId('log-');
    const time = timeISO ? new Date(timeISO) : undefined;

    return {
        id,
        timeISO,
        time,
        message,
        sender: sender?.toLowerCase(),
        ...rest,
    } as Log;
};