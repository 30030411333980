import { useState } from 'react';
import { Level } from '../types';
import {useIdentitiesState} from "./useIdentitiesState";

export type Controls = {
	levels: Level[];
	senders: string[];
	message: string;
};

export type FiltersName = keyof Controls;

export const useControls = () => {
	const levels = useIdentitiesState<Level>();
	const senders = useIdentitiesState<string>();
	const [message, setMessage] = useState<string>('');

	const values = {
		message,
		levels: levels.values,
		senders: senders.values,
	};

	const reset = () => [levels, senders].forEach( current => current.reset());

	return {
		values,
		reset,

		addLevel: levels.add,
		toggleLevel: levels.toggle,

		addSender: senders.add,
		toggleSender: senders.toggle,

		changeMessage: setMessage,
	};
};
