import {useState} from "react";

const getToggledState = (state: any[], value: any) => {
    const result = new Set(state);
    if (state.includes(value)) {
        result.delete(value);
    } else {
        result.add(value);
    }
    return Array.from(result);
};

export const useIdentitiesState = <T>() => {
    const [values, setValues] = useState<T[]>([]);
    const toggle = (value: T) =>
        setValues((state) => getToggledState(state, value));

    const add =  (value: T) =>
        setValues((state) => state.includes(value) ? state : [...state, value]);

    const reset = () => setValues([]);

    return { values, add, toggle, reset };
}
