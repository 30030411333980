import {Bookmark} from "../types";
import {BookmarkIcon} from "./bookmark-icon";


type Props = {
    values: Record<string, Bookmark>,
    onClick: (id: string) => void,
};

export const BookmarksGroup: React.FC<Props> = ({ values }) => {
    const bookmarks = Object.entries(values);
    const withBookmarks = !!bookmarks.length;

    return (
        <div>
            <p className='font-bold text-right'>Bookmarks</p>
            <div className="flex flex-row-reverse">
                {withBookmarks
                    ? bookmarks
                        .map((([id, current]) => (
                            <a href={`#${id}`} key={id}>
                                <BookmarkIcon size="large" color={current.color} order={current.order}/>
                            </a>
                        )))
                    : '--Nothing--'
                }
            </div>
        </div>)
};
