import { useEffect } from 'react';
import { getDisplayLogs } from './utils';
import { useControls } from './hooks/useControls';
import { LogPanel } from './components/log-panel';
import { CheckboxGroup } from './components/checkbox-group';
import { Level} from './types';
import { Checkbox } from './components/checkbox';
import { Input } from './components/input';
import { useLogsState } from "./hooks/useLogsState";
import { parseLog } from "./utils/parser.utils";
import { useOptionsMemo } from "./hooks/useOptionsMemo";
import {useFilterAutoAddition} from "./hooks/useFilterAutoAddition";
import {BookmarksGroup} from "./components/bookmarks-group";
import {useBookmarksState} from "./hooks/useBookmarksState";

const LevelCheckboxGroup = CheckboxGroup<Level>;
const SenderCheckboxGroup = CheckboxGroup<string>;

function App() {
	const logsHub = useLogsState();

	const controls = useControls();

	const bookmarks = useBookmarksState();

	const levelsOptions = useOptionsMemo<Level>(logsHub.levels);
	const sendersOptions = useOptionsMemo<string>(logsHub.senders);

	const displayLogs = getDisplayLogs(logsHub.values, controls.values);

	const handleClearAllClick = () => {
		logsHub.resetAll();
		controls.reset();
		bookmarks.reset();
	};
	const handleClearLogsClick = () => {
		logsHub.resetLogs();
		bookmarks.reset();
	};

	useFilterAutoAddition<string>(logsHub.senders, controls.values.senders, controls.addSender);
	useFilterAutoAddition<Level>(logsHub.levels, controls.values.levels, controls.addLevel);

	useEffect(() => {
		//@ts-ignore
		if (!window.socket) return;
		//@ts-ignore
		window.socket.on('writeData', (input: any) => {
			const log = parseLog(input);
			logsHub.add(log);
		});
		//@ts-ignore
	}, [window.socket]);

	return (
		<div className='App w-screen h-screen overflow-auto'>
			<header className='flex justify-end z-[1] gap-[20px] sticky top-0 pt-[15px] px-[50px] pb-[5px] border-b border-b-[#4b4747] bg-[#242424]'>
				<BookmarksGroup values={bookmarks.values} onClick={bookmarks.toggle}/>
				<LevelCheckboxGroup
					title='Levels'
					options={levelsOptions}
					selected={controls.values.levels}
					onChange={controls.toggleLevel}
				/>
				<SenderCheckboxGroup
					title='Senders'
					options={sendersOptions}
					selected={controls.values.senders}
					onChange={controls.toggleSender}
				/>
				<Input
					label='Search Message by:'
					value={controls.values.message}
					onChange={controls.changeMessage}
				/>
				<div className="flex flex-col">
					<button className="py-[5px] mb-[5px]" onClick={handleClearAllClick}>Clear All</button>
					<button className="py-[5px] mb-[5px]" onClick={handleClearLogsClick}>Clear Logs</button>
				</div>
			</header>
			<main className='px-[50px] mt-[25px]'>
				{displayLogs.map((log) => (
					<LogPanel
						key={`${log.id}`}
						bookmark={bookmarks.values[log.id]}
						onBookmarkClick={bookmarks.toggle}
						{...log}
					/>
				))}
			</main>
		</div>
	);
}

export default App;
