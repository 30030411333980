type Props = {
	checked: boolean;
	label?: string;
	onChange?: (value: boolean) => void;
};

export const Checkbox: React.FC<Props> = ({ checked, label, onChange }) => {
	const id = `checkbox-id-${label?.replaceAll(' ', '-')}`;
	return (
		<div onClick={() => onChange?.(!checked)}>
			<input id={id} type='checkbox' checked={checked} />
			{!!label && (
				<label htmlFor={id} className='ml-[8px]'>
					{label}
				</label>
			)}
		</div>
	);
};
