import {useRef, useState} from "react";
import {LABEL_COLORS} from "../constants";
import {Color, Bookmark} from "../types";

const INIT_ID = 0;

export const useBookmarksState = () => {
    const [values, setValues] = useState<Record<string, Bookmark>>({});
    const lastId = useRef<number>(INIT_ID);

    const toggle = (value: string) =>
        setValues((state: Record<string, Color>) => {
            if(Object.keys(state).includes(value)) {
                const {[value]: removedBookmark, ...clearedState } = state;
                return clearedState;
            }

            const order = ++lastId.current;
            const colorIndex = order%LABEL_COLORS.length;
            const color:Color = LABEL_COLORS[colorIndex];

            return {...state, [value]: { order, color }};
        });


    const reset = () => {
        setValues({});
        lastId.current = INIT_ID;
    }

    return { values, toggle, reset };
}