import {Bookmark, Level, Log} from '../types';
import {DEFAULT_COLOR} from "../constants";
import {BookmarkIcon} from "./bookmark-icon";

type Props = Log & {
	bookmark?: Bookmark;
	onBookmarkClick: (id: string) => void;
};

const COLORS: Record<Level, string> = {
	INFO: 'text-blue-600',
	WARN: 'text-orange-600',
	ERROR: 'text-red-600',
	DEBUG: 'text-gray-500',
};

export const LogPanel: React.FC<Props> = ({
	id,
	timeISO,
	level,
	sender,
	message,
	bookmark,
	onBookmarkClick,
}) => {
	const colorClass = level ? COLORS[level] : COLORS.INFO;
	return (
		<div id={id}>
			<div className="flex gap-x-[20px] group">
				<button onClick={() => onBookmarkClick(id)} className={`p-0 border-none focus:outline-none ${bookmark ? '' : 'invisible group-hover:visible hover:visible'} `}>
					<BookmarkIcon
						size="small"
						order={bookmark?.order}
						color={bookmark?.color ?? DEFAULT_COLOR}
						/>
				</button>
				<p className={`${colorClass} w-[200px]`}>{timeISO?.replaceAll(/T|Z/g, ' ')}</p>
				<p className={`${colorClass} min-w-min`}>{level}</p>
				<p className={`${colorClass} min-w-min`}>{sender}</p>
				<p className={`${colorClass} grow`}>{message}</p>
			</div>
		</div>
	);
};
